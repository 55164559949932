import { axios } from '@/utils/request'
import { MessageBox } from 'element-ui'
// 引入jsencrypt库
import JSEncrypt from 'jsencrypt'
// 创建JSEncrypt实例
const encryptor = new JSEncrypt()
// 设置公钥
encryptor.setPublicKey('-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2T...')

// TODO: 以下是RSA解密数据,暂不使用
// const privateKey = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDTxX...'
// // 创建JSEncrypt实例
// const decryptor = new JSEncrypt()
// // 设置私钥
// decryptor.setPrivateKey(privateKey)
// 解密数据
// const decryptedData = decryptor.decrypt(encryptedData)

//get请求
export function getAction(url, parameter, tip) {
  // // 加密数据
  // const plainData = parameter
  // const encryptedData = encryptor.encrypt(plainData)
  // console.log(encryptedData)

  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'get',
      params: parameter
    }).then(response => {
      if (response.code === 200 && response.success) {
        resolve(response)
      } else {
        if (tip) {
          MessageBox({ message: response.message, title: "提示信息：", type: 'error' });
          resolve(response)
        } else {
          resolve(response)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })
}
//post请求 tip是否请求报错提示信息
export function postAction(url, parameter, tip) {
  // // 加密数据
  // const plainData = parameter
  // const encryptedData = encryptor.encrypt(plainData)
  // console.log(encryptedData)
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'post',
      data: parameter
    }).then(response => {
      if (response.code === 200 && response.success) {
        resolve(response)
      } else {
        if (tip) {
          MessageBox({ message: response.message, title: "提示信息：", type: 'error' });
          resolve(response)
        } else {
          resolve(response)
        }
      }
    }).catch(error => {
      reject(error)
    })
  })

  // return axios({
  //   url: url,
  //   method:'post' ,
  //   data: parameter
  // })
}
//put请求
export function putAction(url, parameter) {
  // // 加密数据
  // const plainData = parameter
  // const encryptedData = encryptor.encrypt(plainData)
  // console.log(encryptedData)
  return axios({
    url: url,
    method: 'put',
    data: parameter
  })
}
//post method= {post | put}
export function httpAction(url, parameter, method) {
  // // 加密数据
  // const plainData = parameter
  // const encryptedData = encryptor.encrypt(plainData)
  // console.log(encryptedData)
  return axios({
    url: url,
    method: method,
    data: parameter
  })
}
//deleteAction
export function deleteAction(url, parameter) {
  // // 加密数据
  // const plainData = parameter
  // const encryptedData = encryptor.encrypt(plainData)
  // console.log(encryptedData)
  return axios({
    url: url,
    method: 'delete',
    params: parameter
  })
}
